module controllers {
  export module customs {
    interface IRefundClaimUpdateScope extends ng.IScope {}

    interface IRefundClaimUpdateParams extends ng.ui.IStateParamsService {
      refId: number;
    }

    export class refundClaimUpdateCtrl {
      static $inject = [
        "$scope",
        "$stateParams",
        "$rootScope",
        "customsService",
        "classificationValueService",
        "$timeout",
        "generalService",
        "$q",
        "$state",
        "entityService",
        "refundClaimService",
        "countryService",
        "refundClaimTypeService",
        "statusService",
        "$uibModal",
        "bsLoadingOverlayService",
        "$transitions",
        "documentRepositoryService",
        "documentTypeService",
        "taxTypeService",
        "revenueAllocationCodeService",
        "rejectionService",
        "refundSettingService",
        'menuService',
        'companyService',
        'userAccountService',
        "uiGridConstants"
      ];

      public refId: number;
      refundClaim: interfaces.customs.IRefundClaim;
      refundSetting: interfaces.master.IRefundSetting;
      breadCrumbDesc: string;
      selectedTab: number = 0;
      goodsDecList: interfaces.customs.IRefundGoodsDeclarationDisplay[] = [];
      IsLoading: boolean = false;
      showNotClaimed : boolean = false;
      showErrorsWarning : boolean = false;
      apiGoodDecList: uiGrid.IGridApi;
      gridDocumentsApi: uiGrid.IGridApi;
      gridRefundClaimDetailApi: uiGrid.IGridApi;
      gridDutyDrawBackApi: uiGrid.IGridApi;
      myHook: any;
      documentRepository: interfaces.documentManagement.IDocumentRepository;
      documentTypes: ng.resource.IResourceArray<
        interfaces.applicationcore.IDropdownModel
      >;
      functionType: number = 384;
      reason: string = "";

      public permStatus: interfaces.master.IStatusData;
      showAll: boolean = false;
      apiCaseHistoryList: uiGrid.IGridApi;

      lockDownRefundDetailTab: boolean = false; //Lockdown the Refund Claim Detail Tab

      constructor(
        private $scope: IRefundClaimUpdateScope,
        private $stateParams: IRefundClaimUpdateParams,
        private $rootScope: interfaces.applicationcore.IRootScope,
        private customsService: interfaces.customs.ICustomsService,
        private classificationValueService: interfaces.applicationcore.IClassificationValueService,
        private $timeout: ng.ITimeoutService,
        private generalService: interfaces.applicationcore.IGeneralService,
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private entityService: interfaces.applicationcore.IEntityService,
        private refundClaimService: interfaces.customs.IRefundClaimService,
        private countryService: interfaces.applicationcore.ICountryService,
        private refundClaimTypeService: services.tariff.refundClaimTypeService,
        private statusService: interfaces.master.IStatusService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private bsLoadingOverlayService,
        private $transitions: ng.ui.core.ITransition,
        public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
        private documentTypeService: interfaces.documentManagement.IDocumentTypeService,
        private taxTypeService: services.tariff.taxTypeService,
        private revenueAllocationCodeService: services.tariff.revenueAllocationCodeService,
        private rejectionService: services.master.rejectionService,
        private refundSettingService: interfaces.master.IRefundSettingService,
        private menuService: interfaces.applicationcore.IMenuService,
        private companyService: interfaces.master.ICompanyService,
        private userAccountService: interfaces.applicationcore.IUserAccountService,
        private uiGridConstants: uiGrid.IUiGridConstants,
      ) {
        if ($stateParams.refId == null) {
          return;
        }

        //Get the ID to get the Refund Claim Details.
        this.refId = $stateParams.refId;

        this.loadRefundClaim();

        this.myHook = $transitions.onSuccess(
          {
            to: "auth.RefundClaim.Update",
            from: "auth.RefundClaim.Update.**"
          },
          () => {
            this.loadRefundClaim();
          }
        );

        $scope.$on("$destroy", () => {
          $transitions.onStart({}, this.myHook);
        });
      }

      loadApplicantByCountry(){
        var deferred = this.$q.defer();

        if (this.refundClaim.DeclarationCountryId > 0 && this.refundClaim.OwnerEntity.Id > 0) {
            this.companyService.getCustomsPartyByCountryDropdownList(this.refundClaim.OwnerEntity.Id,this.refundClaim.DeclarationCountryId, Enum.EnumCustomsPartyType.Declarant).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                deferred.resolve(list);
            }, (errorResponse) => {
                deferred.resolve([]);
            });
        }
        else {
            var defer = this.$q.defer();
            defer.resolve([]);
            return defer.promise;
        }

        return deferred.promise;
        }

        loadUsers(searchText: string) {
          if (this.refundClaim) {
              return this.userAccountService.getDropdownList(searchText, this.refundClaim.OwnerEntity.Id).query(
                  (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                  }, (failureData) => {
                      this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                  }).$promise;
          } else {
              var defer = this.$q.defer();
              defer.resolve([]);
              return defer.promise;
          }
        }

      loadStatus() {
        this.permStatus = {
          StatusName: this.refundClaim.StatusName,
          Id: this.refundClaim.Id,
          EntityId: this.refundClaim.OwnerEntity.Id,
          CurrentStatusId: this.refundClaim.StatusId,
          CurrentStatusComments: this.refundClaim.StatusLastComments,
          CurrentStatusStampDate: this.refundClaim.StatusLastUpdateStampDate,
          CurrentStatusStampFullName: this.refundClaim
            .StatusLastUpdateStampFullName,
          CurrentStatusType: Enum.EnumStatusType.RefundClaim,
          EnumStatusType: Enum.EnumStatusType.RefundClaim,
          EnumStatusAuthorisationType: Enum.EnumStatusType.RefundClaim,
          IsEditable: true
        };
      }

      updateStatus = (newStatusId: number, comments: string) => {
        var paramsObject: interfaces.master.IStatusChange = {
          referenceId: this.refundClaim.Id,
          newStatusId: newStatusId,
          comments: comments
        };
        this.bsLoadingOverlayService.wrap(
          {
            referenceId: "refundClaim.update"
          },
          () => {
            return this.refundClaimService.changeStatus().save(
              {},
              paramsObject,
              (data: interfaces.applicationcore.IMessageHandler) => {
                this.bsLoadingOverlayService.wrap(
                  {
                        referenceId: "refundClaim.update"
                  },
                  () => {
                    this.loadRefundClaim();
                    this.selectedTab = 4;
                    return;
                  }
                );
                this.generalService.displayMessageHandler(data);
              },
              failureData => {
                this.generalService.displayMessageHandler(<
                  interfaces.applicationcore.IMessageHandler
                >failureData.data);
              }
            );
          }
        );
      };

      showNotClaimedChange() {
        this.loadRefundClaim();
      }

      showErrorsWarningChange(){
        this.loadRefundClaim();
      }

        loadRefundClaim = () => {
            this.bsLoadingOverlayService.wrap(
              {
                referenceId: "refundClaim.update"
              },
              () => {
                return this.refundClaimService.getRefundClaimDetail().get(
                  {
                    refId: this.refId,
                    isClaim: !this.showNotClaimed,
                    IsErrorsWarning: this.showErrorsWarning
                  },
                    (result: interfaces.customs.IRefundClaim) => {
                        this.refundClaim = result;
                        this.gvwGoodsDec.data = this.refundClaim.GoodsDeclarationList;
                        this.breadCrumbDesc = this.refundClaim.ReferenceNumber;
                        this.gvwReqDocs.data = this.refundClaim.DutyDrawbackClaimDocumentDisplayList;
                        this.gvwRefundClaimDetail.data = this.refundClaim.DutyDrawbackClaimDetails;

                        //Update this.refundClaim.DutyDrawbackClaimDetails OriginalDutiesClaimed = DutiesClaimed
                        this.refundClaim.DutyDrawbackClaimDetails.forEach(element => {
                          element.OriginalDutiesClaimed = element.DutiesClaimed;
                        });

                        //set lockDownRefundDetailTab = true if if this is a 522.03 claim or if the claim has been "Authhroised" / Cancelled or Finalised. Also disable if the EDI has been sent and accepted  by Customs
                        
                        if (this.refundClaim.RefundClaimType.Code === '522.03'){
                          this.lockDownRefundDetailTab = true;
                        }

                        if (this.refundClaim.LockdownType >= 4){
                          this.lockDownRefundDetailTab = true;
                        }

                        if (this.refundClaim.IsSent || this.refundClaim.IsAccepted){
                          this.lockDownRefundDetailTab = true;
                        }



                        this.gvwClaimDetail.data = this.refundClaim.ClaimDetailList;                        
                        this.getDocuments(this.showAll);
                        this.loadStatus();
                  },
                  errorResponse => {
                    this.generalService.displayMessageHandler(<
                      interfaces.applicationcore.IMessageHandler
                    >errorResponse.data);
                  }
                ).$promise;
              }
            );
      }

      ShowConsignment(consignmentId:number,IsInbound:boolean){
        var url = "";
        if (IsInbound){
            url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
        }
        else{                    
            url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
        }

        window.open(url, '_blank');
    }

      loadRefundSetting = () => {
        return this.refundSettingService.getRefundSettingDetail().get(
          {
            refId: this.refId
          },
          (result: interfaces.master.IRefundSetting) => {
            this.refundSetting = result;
          },
          errorResponse => {
            this.generalService.displayMessageHandler(<
              interfaces.applicationcore.IMessageHandler
            >errorResponse.data);
          }
        ).$promise;
      }

      loadTaxTypes = () => {
        return this.taxTypeService
          .getDropdownList(this.refundClaim.DeclarationCountryId)
          .query(
            () => {},
            failureData => {
              this.generalService.displayMessageHandler(<
                interfaces.applicationcore.IMessageHandler
              >failureData.data);
            }
          ).$promise;
      }

      loadRejections = () => {
        return this.rejectionService.getDropdownList(668).query(
          () => {},
          failureData => {
            this.generalService.displayMessageHandler(<
              interfaces.applicationcore.IMessageHandler
            >failureData.data);
          }
        ).$promise;
      }

      loadRevenueAllocationCodes(taxTypeId: number, searchText: string) {
        if (taxTypeId) {
          return this.revenueAllocationCodeService
            .getDropdownList(
              this.refundClaim.DeclarationCountryId,
              taxTypeId,
              this.refundClaim.RefundClaimType.Id,
              searchText,
              1000
            )
            .query(
              () => {},
              failureData => {
                this.generalService.displayMessageHandler(<
                  interfaces.applicationcore.IMessageHandler
                >failureData.data);
              }
            ).$promise;
        }
      }

      generateRefundClaimDocuments = () => {
        this.bsLoadingOverlayService.wrap(
          {
            referenceId: "refundClaim.update"
          },
          () => {
            return this.refundClaimService
              .GenerateRefundClaimDocuments(this.refId)
              .save(
                result => {
                  this.generalService.displayMessageHandler(result);

                  this.IsLoading = false;

                  if (!result.HasErrorMessage) {
                    this.loadRefundClaim();
                  }
                },
                responseError => {
                  this.generalService.displayMessageHandler(responseError.data);
                  this.IsLoading = false;
                }
              ).$promise;
          }
        );
      }

      validateRefundClaim = () => {
        this.bsLoadingOverlayService.wrap(
          {
            referenceId: "refundClaim.update"
          },
          () => {
            return this.refundClaimService.ValidateRefundClaim(this.refId).save(
              result => {
                this.generalService.displayMessageHandler(result);

                this.IsLoading = false;

                if (!result.HasErrorMessage) {
                  this.loadRefundClaim();
                }
              },
              responseError => {
                this.generalService.displayMessageHandler(responseError.data);
                this.IsLoading = false;
              }
            ).$promise;
          }
        );
      }

      AddNewGoodsDec = () => {
        this.$state.go("auth.RefundClaim.Update.GoodsDec", {
          refId: Number(this.refundClaim.Id)
        });
      }

      RemoveGoodsDec = () => {
        if (
          this.apiGoodDecList &&
          this.apiGoodDecList.selection.getSelectedRows().length > 0
        ) {
          this.bsLoadingOverlayService.wrap(
            {
              referenceId: "refundClaim.update"
            },
            () => {
              return this.UpdateRefundClaim();
            }
          );
        } else {
          this.generalService.displayMessage(
            "Please select a Goods Declaration",
            Enum.EnumMessageType.Warning
          );
        }
      }

      printGDCIDocuments = () => {
        if (
          this.apiGoodDecList &&
          this.apiGoodDecList.selection.getSelectedRows().length > 0
        ) {
          var selected = this.apiGoodDecList.selection.getSelectedRows()[0];

          this.$stateParams.gsdId = selected.GoodsDeclarationId;
        
         if(this.$state.current.name === 'auth.ConsignmentExports.Update.RefundClaim') {
           this.$state.go("auth.ConsignmentExports.Update.RefundClaim.GoodsDeclaration", {
            gsdId: selected.GoodsDeclarationId,
            version: selected.Version
          });
        } else {
              this.$state.go("auth.RefundClaim.Update.GoodsDeclaration", {
                gsdId: selected.GoodsDeclarationId,
                version: selected.Version
              });
        }

        /*  this.$state.go("auth.RefundClaim.Update.GoodsDeclaration", {
            gsdId: selected.GoodsDeclarationId,
            version: selected.Version
          });*/
        } else {
          this.generalService.displayMessage(
            "Please select a Goods Declaration",
            Enum.EnumMessageType.Warning
          );
        }
      }

      UpdateRefundClaim = () => {
        this.goodsDecList = this.apiGoodDecList.selection.getSelectedRows();

        this.goodsDecList.forEach(o => {
          o.IsActive = false;
        });

        var goodsDecSelectedList: interfaces.customs.IGoodsDecList = {
          dutyDrawbackClaimToGoodsDecList: []
        };

        goodsDecSelectedList.dutyDrawbackClaimToGoodsDecList = this.goodsDecList;

        return this.refundClaimService.UpdateRefundClaim(this.refId).save(
          goodsDecSelectedList,
          result => {
            this.generalService.displayMessageHandler(result);

            this.IsLoading = false;

            if (!result.HasErrorMessage) {
              this.loadRefundClaim();
            }
          },
          responseError => {
            this.generalService.displayMessageHandler(responseError.data);
            this.IsLoading = false;
          }
        ).$promise;
      }

      registerGridApi = (gridApi: uiGrid.IGridApi) => {
        this.apiGoodDecList = gridApi;
      }

      gvwGoodsDec: uiGrid.IGridOptions = {
        data: [],
        enableFiltering: true,
        useExternalFiltering: false,
        enableCellEdit: false,
        useExternalSorting: false,
        multiSelect: true,
        enableColumnResizing: true,
        enableFullRowSelection: true,
        enableRowSelection: true,
        useExternalPagination: false,
        enableRowHeaderSelection: true,
        enableHorizontalScrollbar: 2,
        enableVerticalScrollbar: 1,
        paginationPageSizes: [10, 20, 50, 100],
        paginationPageSize: 10,
        onRegisterApi: gridApi => {
          this.registerGridApi(gridApi);
        },
        columnDefs: [
          {
            name: "STATUS",
            displayName: "",
            field: "",
            enableCellEdit: false,
            cellTemplate: `
                        <div style="text-align: center; padding-top: 6px;">
                            <div ng-if="row.entity.StatusIndicator === 0">
                                <span class="fa fa-edit"></span>
                            </div>
                            <div ng-if="row.entity.StatusIndicator === 1">
                                <span class="fa fa-times" style="color: #cb2330;"></span>
                            </div>
                            <div ng-if="row.entity.StatusIndicator === 2">
                                <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                            </div>
                            <div ng-if="row.entity.StatusIndicator === 3">
                                <span class="fa fa-check-circle" style="color: #bcd856;"></span>
                            </div>
                        </div>`,
            enableSorting: false,
            enableColumnMenu: false,
            width: 35,
            enableCellEditOnFocus: false
          },
          {
            name: "ConsignmentNumber",
            displayName: "Consignment Number",
            field: "ConsignmentNumber",
            type: "string",
            width: 135,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                              <a style="cursor:pointer" ng-click="grid.appScope.refundClaimUpdateCtrl.ShowConsignment(row.entity.ConsignmentId, row.entity.IsInbound)"><u>{{MODEL_COL_FIELD}}</u></a>
                          </div>`,            
          },
          {
            name: "CPCCode",
            displayName: "CPC",
            field: "CPCCode",
            type: "string",
            width: 75
          },
          {
            name: "MRNNumber",
            displayName: "MRN Number",
            field: "MRNNumber",
            type: "string",
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            width: 145
          },
          ,
          {
            name: "AssessmentDate",
            displayName: "Assessment Date",
            field: "AssessmentDate",
            type: "string",
            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
            width: 147
          },
          {
            name: "Version",
            displayName: "Version",
            field: "Version",
            width: 65
          },
          {
            name: "FunctionType",
            displayName: "Function",
            field: "FunctionType",
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
            type: "string",
            width: 75
          },
          {
            name: "Declarant",
            displayName: "Declarant",
            field: "Declarant",
            type: "string",
            width: 135,
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
          },
          {
            name: "ImporterExporter",
            displayName: "Importer Exporter",
            field: "ImporterExporter",
            type: "string",
            width: 135,
            cellTemplate:
              '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
          }
        ]
      };

      registerDocumentsGridApi = (gridApi: uiGrid.IGridApi) => {
        this.gridDocumentsApi = gridApi;

        this.$timeout(() => {
          this.gvwReqDocs.data = this.refundClaim.DutyDrawbackClaimDocumentDisplayList;
        });
      }

      public gvwReqDocs: uiGrid.IGridOptions | any = {
        data: [],
        enableSorting: true,
        enableFiltering: true,
        useExternalFiltering: false,
        enableCellEdit: false,
        useExternalSorting: false,
        multiSelect: false,
        enableColumnResizing: true,
        enableRowSelection: false,
        enableFullRowSelection: false,
        enableRowHeaderSelection: false,
        enableHorizontalScrollbar: 2,
        enablePinning: false,
        rowEditWaitInterval: -1,
        paginationPageSizes: [10, 20, 50, 100],
        paginationPageSize: 20,
        enableVerticalScrollbar: 1,
        useExternalPagination: true,
        enableGridMenu: true,
        exporterMenuCsv: false,
        exporterMenuPdf: false,
        exporterExcelFilename: "RefundClaimRequiredDocumentList.xlsx",
        gridMenuShowHideColumns: false,
        exporterFieldCallback: (grid, row, col, value) => {
          return this.generalService.handleValue(value);
        },
        onRegisterApi: gridApi => {
          this.registerDocumentsGridApi(gridApi);
        },
        columnDefs: [
          {
            name: "Description",
            displayName: "Description",
            field: "Description",
            width: 450,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            enableCellEditOnFocus: true,
            enableCellEdit: false
          },
          {
            name: "DocumentType",
            displayName: "Document Type",
            field: "DocumentType",
            width: 150,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`
          },
          {
            name: "Mandatory",
            displayName: "Mandatory",
            field: "Mandatory",
            width: 100,
            cellTemplate:
              '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="true">'
          },
          {
            name: "ConsignmentLevel",
            displayName: "Consignment",
            field: "Consignment",
            width: 120,
            cellTemplate:
              '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="true">'
          },
          ,
          {
            name: "DA63ImportDocument",
            displayName: "Linked Import",
            field: "IsDA63ImportDocument",
            width: 120,
            cellTemplate:
              '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="true">',
            visible: `(grid.appScope.refundClaimUpdateCtrl.refundClaim.DeclarationCountry.Id === 249)`,
          },

          {
            name: "IsComplete",
            displayName: "Is Complete",
            field: "IsComplete",
            width: 100,
            cellTemplate:
              '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.refundClaimUpdateCtrl.setRowDirtyDocument(row.entity)" ng-disabled="grid.appScope.refundClaimUpdateCtrl.refundClaim.LockdownType >= 2">'
          },
          {
            name: "Comments",
            displayName: "Comments",
            field: "Comments",
            enableCellEditOnFocus: true,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{row.entity.Comments}}</div>`,
            enableSorting: false,
            enableCellEdit: true,
            editableCellTemplate: `
                                   <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-comment" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor ng-disabled="grid.appScope.refundClaimUpdateCtrl.refundClaim.LockdownType >= 2" />
                                    </p>
                                </form>`,
            width: 300
          },
          {
            name: "CompletedBy",
            displayName: "Completed By",
            field: "CompletedBy",
            width: 120,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            enableCellEditOnFocus: true,
            enableCellEdit: false
          },
          {
            name: "CompletedOn",
            displayName: "Completed On",
            field: "CompletedOn",
            width: 120,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{ MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD HH:mm:ss'}}</div>`,
            enableCellEditOnFocus: true,
            enableCellEdit: false
          }
        ]
      };

      setRowDirtyDocument = (entity) => {
        if (this.gridDocumentsApi) {
          this.gridDocumentsApi.rowEdit.setRowsDirty([entity]);
        }
      }

      //Documents
      getDocuments = (showAll:boolean) => {
        return this.documentRepositoryService.getDocumentRepository().query(
          {
            referenceNumber: this.refundClaim.ReferenceNumber,
            systemType: 756,
            ownerEntityId: this.refundClaim.OwnerEntity.Id,
            showAll:showAll
          },
          (
            documentRepository: interfaces.documentManagement.IDocumentRepository
          ) => {
            this.documentRepository = documentRepository;

            if (this.documentRepository) this.loadDocumentTypes();
          },
          errorResponse => {
            this.generalService.displayMessageHandler(<
              interfaces.applicationcore.IMessageHandler
            >errorResponse.data);
          }
        ).$promise;
      }

      refreshDocuments = (showAll:boolean) => {
        this.showAll = showAll;
        this.documentRepository = null;
        return this.getDocuments(showAll);
      }

      printDocuments = () => {
        if(this.$state.current.name === 'auth.ConsignmentExports.Update.RefundClaim') {
          if (this.$stateParams.refId) {
            this.$state.go("auth.ConsignmentExports.Update.RefundClaim.Documents");
          }
       } else {
        if (this.$stateParams.refId) {
          this.$state.go("auth.RefundClaim.Update.Documents");
        }
       }
      }

      loadDocumentTypes = () => {
        return this.documentTypeService.getDocumentTypes().query(
          {
            repTypeId: this.documentRepository.RepositoryTypeId,
            ownerEntityId: this.documentRepository.OwnerEntityId,
            referenceNumber: this.refundClaim.ReferenceNumber
          },
          (
            documentTypes: ng.resource.IResourceArray<
              interfaces.applicationcore.IDropdownModel
            >
          ) => {
            this.documentTypes = documentTypes;
          },
          errorResponse => {
            this.generalService.displayMessageHandler(<
              interfaces.applicationcore.IMessageHandler
            >errorResponse.data);
          }
        ).$promise;
      }

      saveRefundClaim = () => {
        return this.bsLoadingOverlayService.wrap(
          {
            referenceId: "refundClaim.update"
          },
          () => {
            if (
              this.gridDocumentsApi &&
              this.gridDocumentsApi.rowEdit.getDirtyRows().length > 0
            ) {
              this.refundClaim.DutyDrawbackClaimDocumentDisplayList = _.map(
                this.gridDocumentsApi.rowEdit.getDirtyRows(),
                o => {
                  return o.entity;
                }
              );
            } else {
              this.refundClaim.DutyDrawbackClaimDocumentDisplayList = [];
            }

            if (
              this.gridRefundClaimDetailApi &&
              this.gridRefundClaimDetailApi.rowEdit.getDirtyRows().length > 0
            ) {
              this.refundClaim.DutyDrawbackClaimDetails = _.map(
                this.gridRefundClaimDetailApi.rowEdit.getDirtyRows(),
                o => {
                  return o.entity;
                }
              );
            } else {
              this.refundClaim.DutyDrawbackClaimDetails = [];
            }

            console.log("this.refundClaim.DutyDrawbackClaimDetails ",this.refundClaim.DutyDrawbackClaimDetails );

            return this.refundClaimService.saveRefundClaim().save(
              this.refundClaim,
              (result: interfaces.applicationcore.IMessageHandler) => {
                this.generalService.displayMessageHandler(result);

                if (!result.HasErrorMessage) {
                  this.loadRefundClaim();

                  if (this.gridDocumentsApi) {
                    this.gridDocumentsApi.rowEdit.setRowsClean(
                      this.refundClaim.DutyDrawbackClaimDocumentDisplayList
                    );
                  }

                  if (this.gridRefundClaimDetailApi) {
                    this.gridRefundClaimDetailApi.rowEdit.setRowsClean(
                      this.refundClaim.DutyDrawbackClaimDetails
                    );
                  }
                }
              },
              errorResult => {
                this.generalService.displayMessageHandler(errorResult.data);
              }
            ).$promise;;
          },
          failureData => {
            this.generalService.displayMessageHandler(<
              interfaces.applicationcore.IMessageHandler
            >failureData.data);
            // this.$anchorScroll("topAnchor");
          }
        );
      }

      saveCloseRefundClaim = () => {
        this.saveRefundClaim();
        this.close();
      }

      close = () => {
        this.$state.go("^");
      }

      createNewVersion_Click = () => {
        this.generalService
          .displayConfirmationBox("Confirm?", "Create new version?")
          .then((result: boolean) => {
            if (result) {
              this.createNewVersion(
                this.refundClaim.Id,
                4,
                "Create New Version"
              );
            }
          });
      }

      createNewVersion = (id: number, functionClassificationValueId: number, reason: string) => {
        this.refundClaimService.CreateNewVersion().save(
          { id, functionClassificationValueId, reason },
          () => {
            //Result
            this.loadRefundClaim();
          },
          failureData => {
            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
          }
        );
      }

      registerRefundClaimDetailGridApi = (gridApi: uiGrid.IGridApi) => {
        this.gridRefundClaimDetailApi = gridApi;

        this.$timeout(() => {
          this.gvwRefundClaimDetail.data = this.refundClaim.DutyDrawbackClaimDetails;          
        
        });

        
      }

      setRowDirtyRefundClaimDetail = (entity) => {
        if (this.gridRefundClaimDetailApi) {    
          this.gridRefundClaimDetailApi.rowEdit.setRowsDirty([entity]);
        }
      }

      isRefundClaimChanged = (exportGoodsDecLineDutiesId:number,isRefundClaim:boolean) => {
       
          //flag all the rows in  this.refundClaim.DutyDrawbackClaimDetails where ExportGoodsDecLineDutiesId =  exportGoodsDecLineDutiesId  IsRefundClaim = isRefundClaim
          this.refundClaim.DutyDrawbackClaimDetails.forEach(element => {
            if (element.ExportGoodsDecLineDutiesId === exportGoodsDecLineDutiesId){
              element.IsRefundClaim = isRefundClaim;

              if (!isRefundClaim){
                element.DutiesClaimed = 0;
              }
              else{
                element.DutiesClaimed = element.OriginalDutiesClaimed;
              }

              this.setRowDirtyRefundClaimDetail(element);
            }
          });
      }
        

      gvwRefundClaimDetail: uiGrid.IGridOptions | any = {
        data: [],
        enableSorting: true,
        enableFiltering: true,
        useExternalFiltering: false,
        enableCellEdit: true,
        useExternalSorting: false,
        multiSelect: false,
        enableColumnResizing: true,
        enableRowSelection: false,
        enableFullRowSelection: true,
        enableRowHeaderSelection: false,
        enableHorizontalScrollbar: 2,
        enablePinning: true,
        rowEditWaitInterval: -1,
        paginationPageSizes: [10, 20, 50, 100],
        paginationPageSize: 10,
        enableVerticalScrollbar: 1,
        useExternalPagination: false,
        enableGridMenu: true,
        exporterMenuCsv: false,
        exporterMenuPdf: false,
         exporterExcelFilename: "RefundClaimDetails.xlsx",
        gridMenuShowHideColumns: false,  
        exporterFieldCallback: (grid, row, col, value) => {
          return this.generalService.handleValue(value);
        },
        onRegisterApi: gridApi => {
          this.registerRefundClaimDetailGridApi(gridApi);
        },
        columnDefs: [

          {
            name: "ExportMRNNumber",
            displayName: "MRN Number",
            field: "ExportMRNNumber",
            width: 150,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,                
          },               
          {
            name: "ExportLineNo",
            displayName: "MRN Line Number",
            field: "ExportLineNo",
            cellClass: 'text-right',
            width: 80,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                   
          },                 
          {
            name: "ExportDescription",
            displayName: "Description",
            field: "ExportDescription",
            width: 150,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`
          },            
          {
            name: "ExportTarrifCode",
            displayName: "Tariff Code",
            field: "ExportTarrifCode",
            width: 100,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`
          },
          {
            name: "CountryOfOrigin",
            displayName: "Origin",
            field: "CountryOfOrigin",
            width: 80,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
          },               
          {
            name: "ProcedureMeasure",
            displayName: "Procedure Measure",
            field: "ProcedureMeasure",
            width: 100,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
          },                   
          {
            name: "PreviousProcedureAssesmentNumber",
            displayName: "Previous Procedure MRN",
            field: "PreviousProcedureAssesmentNumber",
            width: 200,
            cellTemplate: `
                <div class="ui-grid-cell-contents gts-link" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="MODEL_COL_FIELD" ng-click="grid.appScope.refundClaimUpdateCtrl.MRNPopup(row.entity.PreviousProcedureGoodDeclarationLineId,row.entity.ImportConsignmentNumber)">{{MODEL_COL_FIELD}}</div>
                <div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="!MODEL_COL_FIELD">{{MODEL_COL_FIELD}}</div>
              `,
              filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`
          },               
          {
            name: "PreviousProcedureAssesmentLineNumber",
            displayName: "#",
            field: "PreviousProcedureAssesmentLineNumber",
            width: 50,
            cellClass: 'text-right',
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    
          },                            
          {
            name: "ImportConsignmentNumber",
            displayName: "Previous Consignment",
            field: "ImportConsignmentNumber",
            width: 120,
            cellTemplate: `
                <div class="ui-grid-cell-contents gts-link" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="MODEL_COL_FIELD" ng-click="grid.appScope.refundClaimUpdateCtrl.GoToConsignment(row.entity.ImportConsignmentId)">{{MODEL_COL_FIELD}}</div>
                <div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="!MODEL_COL_FIELD">{{MODEL_COL_FIELD}}</div>
              `,
              filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    }
          },                           
          {
            name: "IsRefundClaim",
            displayName: "Is Refund Claim",
            field: "IsRefundClaim",
            width: 80,
            cellTemplate:
              '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="grid.appScope.refundClaimUpdateCtrl.lockDownRefundDetailTab" ng-change="grid.appScope.refundClaimUpdateCtrl.isRefundClaimChanged(row.entity.ExportGoodsDecLineDutiesId,row.entity.IsRefundClaim)">',
              filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                   
          },                
          {
            name: "TaxTypeCode",
            displayName: "Tax Type",
            field: "TaxTypeCode",
            width: 50,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,  
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                        
          },
          {
            name: "DutyTypeCode",
            displayName: "Duty Type",
            field: "DutyTypeCode",
            width: 50,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    
          },
          {
            name: "DutiesClaimed",
            displayName: "Amount",
            field: "DutiesClaimed",
            cellFilter: "number: 0",
            cellClass: 'text-right',
            width: 100,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
            editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="number"  ng-disabled="grid.appScope.refundClaimUpdateCtrl.lockDownRefundDetailTab" ng-change="grid.appScope.refundClaimUpdateCtrl.setRowDirtyRefundClaimDetail(row.entity)" name="Amount-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" step="0.01" min="0.1" ui-grid-editor ng-disabled="grid.appScope.refundClaimUpdateCtrl.refundClaim.LockdownType >= 2" />
                            </p>
                        </form>`,
            enableCellEditOnFocus: true,
            enableCellEdit: true,
            filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    
          }
        ]
      };

      registerDutyDrawBackGridApi = (gridApi: uiGrid.IGridApi) => {
        this.gridDutyDrawBackApi = gridApi;

        this.$timeout(() => {
          this.gvwClaimDetail.data = this.refundClaim.ClaimDetailList;
        });
      }

    //gvwClaimDetail
    public gvwClaimDetail: uiGrid.IGridOptions | any = {
        data: [],
        enableSorting: true,
        useExternalFiltering: false,
        enableCellEdit: false,
        enableCellEditOnFocus: false,
        useExternalSorting: false,
        multiSelect: false,
        enableColumnResizing: true,
        enableRowSelection: false,
        enableFullRowSelection: false,
        enableRowHeaderSelection: false,
        enableHorizontalScrollbar: 2,
        enablePinning: false,
        rowEditWaitInterval: -1,
        paginationPageSizes: [10, 20, 50, 100],
        paginationPageSize: 10,
        enableVerticalScrollbar: 1,
        useExternalPagination: true,
        enableGridMenu: true,
        exporterMenuCsv: false,
        exporterMenuPdf: false,
        exporterExcelFilename: "DutyDrawBackList.xlsx",
        gridMenuShowHideColumns: false,
        exporterFieldCallback: (grid, row, col, value) => {
          return this.generalService.handleValue(value);
        },
        exporterSuppressColumns: ["ADD"],
        onRegisterApi: gridApi => {
          this.registerDutyDrawBackGridApi(gridApi);
        },
        columnDefs: [
          {
            name: "isClaim",
            displayName: "Claim",
            field: "isClaim",
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            width: 60,
            cellTemplate: `
              <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-disabled="grid.appScope.refundClaimUpdateCtrl.refundClaim.SignOffClaim || !row.entity.isCurrentClaim">
            `,
          },  
          {
            name: "TypeIndicator",
            displayName: "",
            field: "TypeIndicator",
            enableCellEdit: false,
            cellTemplate: `
            <div style="text-align: center; padding-top: 6px; cursor:pointer;" ng-click="grid.appScope.refundClaimUpdateCtrl.ViewValidationLogErrorsBySku(row.entity.commercialInvoiceSkuId)">
                <div ng-if="row.entity.TypeIndicator === 0">
                 
                </div>
                <div ng-if="row.entity.TypeIndicator === 2">
                    <span class="fa fa-times" style="color: #cb2330;"></span>
                </div>
                <div ng-if="row.entity.TypeIndicator === 1">
                    <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                </div>
            </div>                                                    
            `,
            enableSorting: false,
            enableColumnMenu: false,
            width: 45,
            enableCellEditOnFocus: false,
        },    
          {
            name: "commercialInvoiceLine",
            displayName: "#",
            field: "commercialInvoiceLine",
            width: 40,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
          },
          {
            name: "SKUCode",
            displayName: "Product",
            field: "SKUCode",
            width: 100,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
          },
          {
            name: "SKUDescription",
            displayName: "Description",
            field: "SKUDescription",
            width: 150,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
          },
          {
            name: "DA63LineNumber",
            displayName: "DA63#",
            field: "DA63LineNumber",
            width: 70,
            cellTemplate: `
                <div class="ui-grid-cell-contents gts-link" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="MODEL_COL_FIELD" ng-click="grid.appScope.refundClaimUpdateCtrl.DA63LinePopup(row.entity.DA63LineId,row.entity.DA63LineNumber,row.entity.DA63ChangeReason,row.entity.isClaim,grid.appScope.refundClaimUpdateCtrl.refundClaim.SignOffClaim)">{{MODEL_COL_FIELD}}</div>
                <div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="!MODEL_COL_FIELD">{{MODEL_COL_FIELD}}</div>
              `,
          },
          {
            name: "Quantity",
            displayName: "Qty",
            field: "Quantity",
            width: 70,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
          },
          {
            name: "SellingValue",
            displayName: "Invoice",
            field: "SellingValue",
            width: 150,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
          },
          {
            name: "estimateDutyClaimable",
            displayName: "Refund",
            field: "estimateDutyClaimable",
            width: 150,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
          },
          {
            name: "CountryOfOrigin",
            displayName: "CoO",
            field: "CountryOfOrigin",
            width: 70,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
          },
          {
            name: "TarrifCode",
            displayName: "Tariff",
            field: "TarrifCode",
            width: 100,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
          },
          {
            name: "MRNNumber",
            displayName: "Import MRN",
            field: "MRNNumber",
            width: 200,
            cellTemplate: `
                <div class="ui-grid-cell-contents gts-link" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="MODEL_COL_FIELD" ng-click="grid.appScope.refundClaimUpdateCtrl.MRNPopup(row.entity.goodsDecLineId,row.entity.consignmentWaybillNumber)">{{MODEL_COL_FIELD}}</div>
                <div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="!MODEL_COL_FIELD">{{MODEL_COL_FIELD}}</div>
              `,
          },
          {
            name: "previousProcedureLineNo",
            displayName: "#",
            field: "previousProcedureLineNo",
            width: 40,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
          },
          {
            name: "consignmentWaybillNumber",
            displayName: "SF",
            field: "consignmentWaybillNumber",
            width: 100,
            cellTemplate: `
                <div class="ui-grid-cell-contents gts-link" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="MODEL_COL_FIELD" ng-click="grid.appScope.refundClaimUpdateCtrl.GoToConsignment(row.entity.consignmentID)">{{MODEL_COL_FIELD}}</div>
                <div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="!MODEL_COL_FIELD">{{MODEL_COL_FIELD}}</div>
              `,
          },
          {
            name: "purchaseOrderCode",
            displayName: "PO",
            field: "purchaseOrderCode",
            width: 150,
            cellTemplate: `
                <div class="ui-grid-cell-contents gts-link" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="MODEL_COL_FIELD" ng-click="grid.appScope.refundClaimUpdateCtrl.GoToPurchaseOrder(row.entity.purchaseOrderId)">{{MODEL_COL_FIELD}}</div>
                <div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}" ng-if="!MODEL_COL_FIELD">{{MODEL_COL_FIELD}}</div>
              `,
          },
        ]
      };

      ViewErrors = () => {
          return this.$uibModal.open({
                animation: true,
                template: `<div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">View Errors</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <div class="vertical-align">
                                    <div class="col-md-12">
                                        <div id="gridTest" ui-grid="gvwViewErrors" ui-grid-resize-columns ui-grid-cellNav class="gridSmallScreen"
                                                ng-if="gvwViewErrors">
                                                <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                    <div class="loadingGrid" ng-show="IsLoading">
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                            </div>
                        </div>`,
                controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, refundClaimService: interfaces.customs.IRefundClaimService) => {
                    $scope.ok = () => {
                        $uibModalInstance.dismiss(false);
                    };

                    $scope.gvwViewErrors = {
                                data: [],
                                enableFiltering: false,
                                useExternalFiltering: false,
                                enableCellEdit: false,
                                useExternalSorting: false,
                                multiSelect: false,
                                enableColumnResizing: true,
                                enableFullRowSelection: false,
                                useExternalPagination: true,
                                enableRowHeaderSelection: false,
                                enableHorizontalScrollbar: 2,
                                enableVerticalScrollbar: 1,
                                onRegisterApi: (gridApi) => {
                                    $scope.IsLoading = true;

                                    refundClaimService.GetCurrentErrorMessages().query({
                                      refId: this.refundClaim.Id
                                    },(result: interfaces.customs.IRefundGoodsDeclarationDisplay) => {
                                        $scope.gvwViewErrors.data = result;
                                        $scope.IsLoading = false;
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                        $scope.IsLoading = false;
                                    });
                                },
                                columnDefs: [{
                                    name: "Sequence",
                                    displayName: "#",
                                    field: "Sequence",
                                    width: 50,
                                }, {
                                    name: "Message",
                                    displayName: "Validation Message",
                                    field: "Message",
                                    width: 760,
                                },
                                ]
                            };
                        },
                        size: "lg",
                        resolve: {
                        }
                    }).result;
      }

      ViewValidationLogErrorsBySku = (commercialInvoiceSkuId:number) => {
        return this.$uibModal.open({
              animation: true,
              template: `<div class="modal-content">
                          <div class="modal-header">
                              <h3 class="modal-title" id="modal-title">View Errors</h3>
                          </div>
                          <div class="modal-body" id="modal-body">
                              <div class="vertical-align">
                                  <div class="col-md-12">
                                      <div id="gridTest" ui-grid="gvwViewErrors" ui-grid-resize-columns ui-grid-cellNav class="gridSmallScreen"
                                              ng-if="gvwViewErrors">
                                              <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                  <div class="loadingGrid" ng-show="IsLoading">
                                                      <i class="fa fa-spinner fa-spin"></i>
                                                  </div>
                                              </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="modal-footer">
                              <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                          </div>
                      </div>`,
              controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, refundClaimService: interfaces.customs.IRefundClaimService) => {
                  $scope.ok = () => {
                      $uibModalInstance.dismiss(false);
                  };

                  $scope.gvwViewErrors = {
                              data: [],
                              enableFiltering: false,
                              useExternalFiltering: false,
                              enableCellEdit: false,
                              useExternalSorting: false,
                              multiSelect: false,
                              enableColumnResizing: true,
                              enableFullRowSelection: false,
                              useExternalPagination: true,
                              enableRowHeaderSelection: false,
                              enableHorizontalScrollbar: 2,
                              enableVerticalScrollbar: 1,
                              onRegisterApi: (gridApi) => {
                                  $scope.IsLoading = true;

                                  refundClaimService.GetDutyDrawbackClaimValidationLog().query({
                                    commercialInvoiceSkuId: commercialInvoiceSkuId
                                  },(result: interfaces.customs.IDutyDrawbackClaimValidationLog) => {
                                      $scope.gvwViewErrors.data = result;
                                      $scope.IsLoading = false;
                                  }, (errorResponse) => {
                                      this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                      $scope.IsLoading = false;
                                  });
                              },
                              columnDefs: [
                                 {
                                  name: "Message",
                                  displayName: "Validation Message",
                                  field: "Message",
                                  width: 760,
                              },
                              ]
                          };
                      },
                      size: "lg",
                      resolve: {
                      }
                  }).result;
    }      

      SignOffClaim = () => {
          this.bsLoadingOverlayService.wrap(
              {
                  referenceId: "refundClaim.update"
              },
              () => {
                //this.saveRefundClaim();

                return this.saveRefundClaim().then((data) => {
                  return this.refundClaimService
                  .SignOffClaim(this.refId)
                  .save(
                      result => {
                          this.generalService.displayMessageHandler(result);

                          this.IsLoading = false;

                          if (!result.HasErrorMessage) {
                              this.loadRefundClaim();
                          }
                      },
                      responseError => {
                          this.generalService.displayMessageHandler(responseError.data);
                          this.IsLoading = false;
                      }
                  ).$promise;     
                })                        
              }
          );
      }

      UnsignOffClaim = () => {
          this.bsLoadingOverlayService.wrap(
              {
                  referenceId: "refundClaim.update"
              },
              () => {

                return this.saveRefundClaim().then((data) => {
                  return this.refundClaimService
                      .UnsignOffClaim(this.refId)
                      .save(
                          result => {
                              this.generalService.displayMessageHandler(result);

                              this.IsLoading = false;

                              if (!result.HasErrorMessage) {
                                  this.loadRefundClaim();
                              }
                          },
                          responseError => {
                              this.generalService.displayMessageHandler(responseError.data);
                              this.IsLoading = false;
                          }
                      ).$promise;
                  })
              }
          );
      }

      RerunDutyRefundCalculation = () => {
          this.bsLoadingOverlayService.wrap(
              {
                  referenceId: "refundClaim.update"
              },
              () => {
                  return this.refundClaimService
                      .RerunDutyRefundCalculation(this.refId)
                      .save(
                          result => {
                              this.generalService.displayMessageHandler(result);

                              this.IsLoading = false;

                              if (!result.HasErrorMessage) {
                                  this.loadRefundClaim();
                              }
                          },
                          responseError => {
                              this.generalService.displayMessageHandler(responseError.data);
                              this.IsLoading = false;
                          }
                      ).$promise;
              }
          );
      }

      RecalculateTariffCodeAndOrigin = () => {
          this.bsLoadingOverlayService.wrap(
              {
                  referenceId: "refundClaim.update"
              },
              () => {
                  return this.refundClaimService
                      .RecalculateTariffCodeAndOrigin(this.refId)
                      .save(
                          result => {
                              this.generalService.displayMessageHandler(result);

                              this.IsLoading = false;

                              if (!result.HasErrorMessage) {
                                  this.loadRefundClaim();
                              }
                          },
                          responseError => {
                              this.generalService.displayMessageHandler(responseError.data);
                              this.IsLoading = false;
                          }
                      ).$promise;
              }
          );
      }

      CollateImportDocuments = () => {
          this.bsLoadingOverlayService.wrap(
              {
                  referenceId: "refundClaim.update"
              },
              () => {
                  return this.refundClaimService
                      .CollateImportDocuments(this.refId)
                      .save(
                          result => {
                              this.generalService.displayMessageHandler(result);

                              this.IsLoading = false;

                              if (!result.HasErrorMessage) {
                                  this.loadRefundClaim();
                              }
                          },
                          responseError => {
                              this.generalService.displayMessageHandler(responseError.data);
                              this.IsLoading = false;
                          }
                      ).$promise;
              }
          );
      }


        SaveAndValidateRefundClaimEDI() {
         
          return this.refundClaimService.saveAndValidateEDI().save((this.refundClaim), (result: interfaces.applicationcore.IMessageHandler) => {
              if (!result.HasErrorMessage) {
                  //Validated
              }
          }, (failureData) => {
              this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
          }).$promise;
      }

        validateEDI() {
            this.$timeout(() => {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.gsd'
                }, () => {
                    return this.saveRefundClaim().then((data) => {

                        if (!data.HasErrorMessage) {
                            return this.SaveAndValidateRefundClaimEDI().then((result) => {
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.$timeout(() => {
                                        this.loadRefundClaim();
                                        this.selectedTab = 3;
                                    });
                                }
                            });
                        } else {
                            this.generalService.displayMessageHandler(data);
                        }
                    }, (data) => {
                        this.generalService.displayMessageHandler(data);
                    });
                });
            });
        }

        ValidateAndSendEDI() {
          return this.refundClaimService.validateAndSendEDI().save((this.refundClaim), (result: interfaces.applicationcore.IMessageHandler) => {

              if (!result.HasErrorMessage) {
                  //Validated and Sent.
                  this.$timeout(() => {
                      this.loadRefundClaim();
                      this.selectedTab = 3;
                  });
              }
          }, (failureData) => {
              this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
          }).$promise;
      }

      DownloadRefundClaimDetailCSV(){
        this.bsLoadingOverlayService.wrap({
            referenceId: 'refundClaim.update'
        },
            () => {
                return this.refundClaimService.getRefundClaimDetailCSV(this.refundClaim.Id).then(() => {
                }, (data) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            });
      }

      DownloadRefundClaimDetailExcel(){
        this.bsLoadingOverlayService.wrap({
            referenceId: 'refundClaim.update'
        },
            () => {
                return this.refundClaimService.getRefundClaimDetailExcel(this.refundClaim.Id).then(() => {
                }, (data) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            });
      }    

      queueRefundClaimDocuments_Click(){
        
         return this.bsLoadingOverlayService.wrap({
              referenceId: 'refundClaim.update'
          }, () => {
              
            return this.refundClaimService.queueRefundDocumentsToCase().save(
              { id:this.refundClaim.Id },
              (data) => {
                //Result
                this.generalService.displayMessageHandler(data);
              },
              failureData => {
                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
              }
            ).$promise;

          });
      
      }

      IsDeclarationCompleteChanged(){
        this.saveRefundClaim();
      }

      createNewVersionEDI_Click(){
        this.displayCreateVersion();
      }

        sendEDI() {
          this.$timeout(() => {
              this.bsLoadingOverlayService.wrap({
                  referenceId: 'con.update.gsd'
              }, () => {
                  return this.saveRefundClaim().then((data) => {


                      if (!data.HasErrorMessage) {
                          return this.ValidateAndSendEDI().then((result) => {
                              this.generalService.displayMessageHandler(result);
  
                              if (!result.HasErrorMessage) {
                                  this.$timeout(() => {
                                      this.loadRefundClaim();
                                      this.selectedTab = 3;
                                  });
                              }
                          });
                      } else {
                          this.generalService.displayMessageHandler(data);
                      }
                  }, (data) => {

                      this.generalService.displayMessageHandler(data);
                  });
              });
          });
      }

        requestEDIResponseResend(){
          //not implemented?
        }

        refresh(){
          if (this.refundClaim.EDIFACTMessageList && this.refundClaim.EDIFACTMessageList.length > 0) {
            this.loadRefundClaim();
            this.selectedTab = 3;
        }
        else {
            this.generalService.displayMessage("The refund claim has not been EDI'd yet", Enum.EnumMessageType.Error);         
        }
      }

                  //Display the Create Version
                  displayCreateVersion() {
  
    
                    this.$uibModal.open({
                        animation: true,
                        template: `
                        <form name="ChangeReason">
                            <div class="modal-content">
                                <div class="gts-padding">
                                    <div class="modal-header">
                                        <h3 class="modal-title" id="modal-title">Create New Version</h3>
                                    </div>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <button type="button" class="btn btn-primary" ng-click="updateType('Amendment')" ng-disabled="!reason">Amendment</button>
                                            </div>
                                            <div class="col-md-6">
                                                <button type="button" class="btn btn-primary" ng-click="updateType('Cancellation')" ng-disabled="!reason">Cancellation</button>
                                            </div>
                                        </div>
                                        <br/>
                                        <div class="row">
                                            <p class="input-group-sm has-feedback" ng-class="{'has-error': ChangeReason.Reason.$invalid}">
                                                    <textarea rows="3" name="Reason" ng-model="reason" class="form-control" ng-change="changeReason()"></textarea>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-info" ng-click="ok()">Close</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        `,
                        controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                        ) => {
                            $scope.reason;
                            $scope.ok = () => {
                                $uibModalInstance.dismiss();
                            }
    
                            $scope.updateType = (type: string) => {
                                if (type == "Amendment") {
                                    this.functionType = 385;
                                }
    
                                if (type == "Cancellation") {
                                    this.functionType = 386;
                                }
    
                                this.createNewVersionEDI(this.refId,this.functionType,this.reason);
                                $uibModalInstance.dismiss();
                            }
    
                            $scope.changeReason = () => {
                                this.reason = $scope.reason;
                            }
                        },
                        controllerAs: "createVersionCtrl",
                        size: "md",
                        resolve: {
                        }
                    });
                }

                createNewVersionEDI = (id: number, functionClassificationValueId: number, reason: string) => {
                  this.refundClaimService.createNewVersionEDI().save(
                    { id, functionClassificationValueId, reason },
                    () => {
                      //Result
                      this.loadRefundClaim();
                    },
                    failureData => {
                      this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }
                  );
                }

                CalculateDA64ClaimAmount_Click(){

                  this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.gsd'
                }, () => {
                            this.refundClaimService.CalculateDA64ClaimAmount(this.refundClaim.Id).save(
                              {},
                              () => {
                                //Result
                                this.loadRefundClaim();
                              },
                              failureData => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                              }
                            ).$promise;
                          });            
                }

         //Open Case History
         caseHistory_Click() {
          this.$uibModal.open({
              animation: true,
              template: `
              <form name="ChangeReason">
                  <div class="modal-content">
                      <div class="gts-padding">
                          <div class="modal-header">
                              <h3 class="modal-title" id="modal-title">View Case History</h3>
                          </div>
                          <div class="modal-body" id="modal-body">
                              <div class="row vertical-align">
                                  <div class="col-md-12">
                                      <div id="gsdCaseHistList" ui-grid="gvwCaseHistory" ui-grid-resize-columns ui-grid-selection ui-grid-cellNav ui-grid-edit ui-grid-row-edit class="gridSmallSmall">
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="modal-footer">
                              <button type="button" class="btn btn-info" ng-click="ok()">Close</button>
                          </div>
                      </div>
                  </div>
              </form>
              `,
              controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
              ) => {
                  $scope.gvwCaseHistory = this.gvwCaseHistory;
                  $scope.ok = () => {
                      $uibModalInstance.dismiss();
                  }

                  $scope.openCase = (id: number) => {
                      this.openCase(id);
                  }
              },
              controllerAs: "viewCaseHistoryCtrl",
              size: "lg",
              resolve: {
              }
          });
      }

      openCase(id: number)
      {
          this.createCase_Click(id);
      }

      viewCase_Click(){
        
        if (this.refundClaim.CaseId)
          this.createCase_Click(this.refundClaim.CaseId);

      }

         //Create Case Click
         createCase_Click(caseId: number) {
          if (caseId) {
              this.bsLoadingOverlayService.wrap({
                  referenceId: 'con.update.gsd'
              }, () => {
                  return this.menuService.getGTSConnectMenuItem(190).get((result: interfaces.applicationcore.IMenu) => {
                      if (result.url) {
                          result.url += "?Id=" + caseId;
                          var url = this.$state.href("auth.IFrame", { url: result.url });
                          window.open(url, '_blank');
                      }
                  }).$promise;
              });
          }
      }

      gvwCaseHistory: uiGrid.IGridOptions =
      {
          data: [],
          enableFiltering: false,
          useExternalFiltering: false,
          enableCellEdit: false,
          useExternalSorting: false,
          multiSelect: false,
          enableColumnResizing: true,
          enableFullRowSelection: true,
          useExternalPagination: false,
          enableRowHeaderSelection: false,
          enableCellEditOnFocus: false,
          enableHorizontalScrollbar: 2,
          rowEditWaitInterval: -1,
          cellEditableCondition: false,
          showGridFooter: true,
          onRegisterApi: (gridApi) => { this.registerCaseHistoryGridApi(gridApi); },
          columnDefs: [{
              name: "VIEW",
              displayName: "",
              field: "",
              cellTemplate: `
              <div class="GridButton"> 
                  <button type="button" ng-click="grid.appScope.openCase(row.entity.Id)" class="btn btn-default btn-sm">
                      <span class="fa fa-eye"></span>
                  </button>
              </div>`,
              enableSorting: false,
              enableColumnMenu: false,
              width: 35
          }, {
              name: "CASENUMBER",
              displayName: "Case Number",
              field: "CaseNumber",
              type: "string",
              cellClass: 'text-right',
              cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
          }, {
            name: "AGENCY",
            displayName: "Agency",
            field: "Agency",
            type: "string",
            cellClass: 'text-right',
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
        }, {
              name: "CREATEDDATE",
              displayName: "Created Date",
              field: "CreatedDate",
              type: "string",
              cellClass: 'text-right',
              cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
          }, {
              name: "SUBMITTEDDATE",
              displayName: "Submitted Date",
              field: "SubmittedDate",
              type: "string",
              cellClass: 'text-right',
              cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
          }, {
              name: "CLOSEDDATE",
              displayName: "Closed Date",
              field: "ClosedDate",
              type: "string",
              cellClass: 'text-right',
              cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
          } ]
      };

      registerCaseHistoryGridApi(gridApi: uiGrid.IGridApi) {
          this.apiCaseHistoryList = gridApi;

          this.apiCaseHistoryList.cellNav.on.navigate(this.$scope, (gridSelection) => {
              this.apiCaseHistoryList.selection.selectRow(gridSelection.row.entity);
          });

          this.$timeout(() => {
              this.gvwCaseHistory.data = this.refundClaim.CaseHistoryList;
          });
      }


      DA63LinePopup = (da63LineIdIn: number,dA63LineNumber:number,DA63ChangeReason : string, isClaim:boolean, isClaimSignedOff :boolean) => {
        return this.$uibModal.open({
              animation: true,
              template: `<div class="modal-content">
                          <div class="modal-header">
                              <h3 class="modal-title" id="modal-title">DA63 Line</h3>
                          </div>
                          <div class="modal-body" id="modal-body">
                              <div class="row vertical-align">
                                <div class="col-md-2">
                                    <opt></opt>
                                    <label>DA63 Line Number</label>
                                </div>
                                <div class="col-md-4">
                                    {{dA63LineNumber}}
                                </div>
                              </div>
                              <div class="row vertical-align">
                                <div class="col-md-2">
                                    <req></req>
                                    <label>Reason for Change</label>
                                </div>
                                <div class="col-md-5">
                                    <p class="input-group-sm">
                                        <textarea rows="3" ng-disabled="!isClaim || isClaimSignedOff"  name="reason" ng-model="reasonForChange" class="form-control"></textarea>
                                    </p>
                                </div>
                              </div>
                              <div class="row vertical-align">
                                <div class="col-md-2">
                                    <opt></opt>
                                    <label>Remove from Claim?</label>
                                </div>
                                <div class="col-md-3">
                                    <input type="checkbox" ng-disabled="!isClaim || isClaimSignedOff"  name="removeClaim" ng-model="removeFromClaim" />
                                </div>
                              </div>
                          </div>
                          <div class="modal-footer">
                              <button class="btn btn-primary" type="button" ng-disabled="!isClaim || !reasonForChange || isClaimSignedOff" ng-click="SubmitAndClose()">Submit And Close</button>
                              <button class="btn btn-info" type="button" ng-click="Close()">Close</button>
                          </div>
                      </div>`,
              controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, refundClaimService: interfaces.customs.IRefundClaimService) => {
                  $scope.SubmitAndClose = () => {
                    $scope.IsLoading = true;

                    refundClaimService.DA63EditLine().query({
                      da63LineId: $scope.da63LineId,
                      reasonForChange: encodeURIComponent($scope.reasonForChange),
                      removeFromClaim: $scope.removeFromClaim,
                    },(result) => {
                      this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>result);                      
                      $scope.IsLoading = false;
                      this.generalService.displayMessageHandler(result);

                      this.IsLoading = false;

                      if (!result.HasErrorMessage) {
                          this.loadRefundClaim();
                      }

                    }, (errorResponse) => {
                      this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                      $scope.IsLoading = false;
                    });

                    $uibModalInstance.dismiss(false);
                  };

                  $scope.da63LineId = da63LineIdIn;
                  $scope.dA63LineNumber = dA63LineNumber;
                  $scope.isClaimSignedOff = isClaimSignedOff;
                  //$scope.da63ChangeReason = DA63ChangeReason;
                  $scope.isClaim = isClaim;
                  $scope.reasonForChange = DA63ChangeReason;
                  $scope.removeFromClaim = false;

                  $scope.Close = () => {
                    $uibModalInstance.dismiss(false);
                };
              },
              size: "lg",
              resolve: {
              }
          }).result;
      }

      MRNPopup = (goodsDecLineId: number,consignmentWaybillNumber: number) => {
        return this.$uibModal.open({
            animation: true,
            template: `
            <uib-tabset active="0">
            <uib-tab index="0" heading="General">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="modal-title">Import MRN</h3>
                    </div>
                    <div class="modal-body" id="modal-body">
                        <div class="row vertical-align">
                          <div class="col-md-3">
                              <opt></opt>
                              <label>Import Consignment Number</label>
                          </div>
                          <div class="col-md-3">
                              {{importMRNDisplay.ImportConsignmentNumber}}
                          </div>
                          <div class="col-md-3">
                              <opt></opt>
                              <label>Total Duties</label>
                          </div>
                          <div class="col-md-3">
                              {{importMRNDisplay.TotalDuties | number: 2}} {{importMRNDisplay.CurrencyCode}}
                          </div>
                        </div>
                        <div class="row vertical-align">
                          <div class="col-md-3">
                              <opt></opt>
                              <label>MRN Number</label>
                          </div>
                          <div class="col-md-3">
                              {{importMRNDisplay.MRN}}
                          </div>
                          <div class="col-md-3">
                              <opt></opt>
                              <label>Total Claims</label>
                          </div>
                          <div class="col-md-3">
                              {{importMRNDisplay.TotalClaims | number: 2}} {{importMRNDisplay.CurrencyCode}}
                          </div>
                        </div>
                        <div class="row vertical-align">
                          <div class="col-md-3">
                              <opt></opt>
                              <label>Office of Entry</label>
                          </div>
                          <div class="col-md-3">
                              {{importMRNDisplay.OfficeOfEntry}}
                          </div>
                          <div class="col-md-3">
                              <opt></opt>
                              <label>Balance</label>
                          </div>
                          <div class="col-md-3">
                              {{importMRNDisplay.Balance | number: 2}} {{importMRNDisplay.CurrencyCode}}
                          </div>
                        </div>
                        <div class="row vertical-align">
                          <div class="col-md-6">
                          </div>
                          <div class="col-md-3">
                              <opt></opt>
                              <label>Total Customs Value</label>
                          </div>
                          <div class="col-md-3">
                              {{importMRNDisplay.TotalCustomsValue | number: 2}} {{importMRNDisplay.CurrencyCode}}
                          </div>
                        </div>
                        <br />
                        <div id="gridTest" ui-grid="gvwDutyRefundClaim" ui-grid-resize-columns ui-grid-cellNav class="gridSmallScreen"
                                ng-if="gvwDutyRefundClaim">
                                <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                    <div class="loadingGrid" ng-show="IsLoading">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-info" type="button" ng-click="Close()">Close</button>
                    </div>
                  </div>
            </uib-tab>
              <uib-tab index="1" heading="Document Repository">
                  <div class="gts-padding">
                    <gts-document-repository document-repository="documentRepository" ng-if="documentRepository" refresh="refreshDocuments(showAll)"  show-all="showAll"></gts-document-repository>
                  </div>
              </uib-tab>
            </uib-tabset>`,
            controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, refundClaimService: interfaces.customs.IRefundClaimService) => {

              $scope.showAll = false;
               
               this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: consignmentWaybillNumber, systemType: Enum.EnumSystemType.Consignment, ownerEntityId: 1, showAll:$scope.showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    $scope.documentRepository = documentRepository;
  
                  }
               );
               
                $scope.Close = () => {
                  $uibModalInstance.dismiss(false);
                };

                $scope.documentRepository = {};

                $scope.printDocuments = () =>
                {
                  
                }

                $scope.refreshDocuments = (showAll:boolean) => {
                  $scope.showAll = showAll;
                  return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: consignmentWaybillNumber, systemType: Enum.EnumSystemType.Consignment, ownerEntityId: 1, showall:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    $scope.documentRepository = documentRepository;
  
                  }, (errorResponse) => {
                      this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                  }).$promise;
              }
                
                
                $scope.importMRNDisplay = {};

                $scope.gvwDutyRefundClaim = {
                          data: [],
                          enableFiltering: false,
                          useExternalFiltering: false,
                          enableCellEdit: false,
                          useExternalSorting: false,
                          multiSelect: false,
                          enableColumnResizing: true,
                          enableFullRowSelection: false,
                          useExternalPagination: true,
                          enableRowHeaderSelection: false,
                          enableHorizontalScrollbar: 2,
                          enableVerticalScrollbar: 1,
                          onRegisterApi: (gridApi) => {
                              $scope.IsLoading = true;

                              refundClaimService.GetImportMRNDisplay().query({
                                refId: goodsDecLineId
                              },(result: interfaces.customs.IImportMRNDisplay) => {
                                  $scope.importMRNDisplay = result;
                                  $scope.gvwDutyRefundClaim.data = result.DutyDrawbackClaimsList;
                                  $scope.IsLoading = false;
                              }, (errorResponse) => {
                                  this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                  $scope.IsLoading = false;
                              });
                          },
                          columnDefs: [{
                              name: "SeqNo",
                              displayName: "#",
                              field: "SeqNo",
                              width: 50,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                          }, {
                              name: "TariffCode",
                              displayName: "Tariff Code",
                              field: "TariffCode",
                              width: 150,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                          }, {
                              name: "OriginCountryCode",
                              displayName: "CoO",
                              field: "OriginCountryCode",
                              width: 50,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                          }, {
                              name: "CustomsValue",
                              displayName: "Customs Value",
                              field: "CustomsValue",
                              width: 200,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                          }, {
                              name: "Duties",
                              displayName: "Duties",
                              field: "Duties",
                              width: 150,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                          }, {
                              name: "Balance",
                              displayName: "Balance",
                              field: "Balance",
                              width: 150,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                          }, {
                              name: "WaybillNumber",
                              displayName: "Consignment",
                              field: "WaybillNumber",
                              width: 150,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                          }, {
                              name: "ExportMRNNumber",
                              displayName: "MRN",
                              field: "ExportMRNNumber",
                              width: 150,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                          }, {
                              name: "ExportLineNumber",
                              displayName: "#",
                              field: "ExportLineNumber",
                              width: 150,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                          }, {
                              name: "DutiesClaimed",
                              displayName: "Duties Claimed",
                              field: "DutiesClaimed",
                              width: 150,
                              cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                          },
                        ]
                  };
              },
              size: "lg",
              resolve: {
              }
          }).result;
      }

      GoToConsignment = (conId: number) => {
        var url = '#!/ConsignmentImports/UpdateImport/'+conId;
        window.open(url, '_blank');
      }

      GoToPurchaseOrder = (poId: number) => {    
          var url = '#!/PurchaseOrders/Update/'+poId;
          window.open(url, '_blank');
      }

    } //End of Module

    angular
      .module("app")
      .controller(
        "refundClaimUpdateCtrl",
        controllers.customs.refundClaimUpdateCtrl
      );
  }
}
